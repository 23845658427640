
import { Contract } from './Contract'
import baseApi from './abis/EthPool'
export class EthPool extends Contract {

  constructor(address) {
    super(address, baseApi)
  }


  async getRewardByAction({ user, amount, deadBlock,nonce,v,r, s}) {
    return await this.sendContract(
      'getRewardByAction',
      [user, amount,deadBlock,nonce,v,r, s]
    )
  }

  async changeSafeAmount(amount) {
    return await this.sendContract(
      'changeSafeAmount',
      [this.toToken(amount, 18)]
    )
  }

  async safeAmount() {
    const safeAmount = await this.callContract(
      'safeAmount',
      []
    )
    return this.fromToken(safeAmount.toString(), 18)
  }

  async owner() {
    return await this.callContract(
      'owner',
      []
    )
  }

}