import store from '@/store'
import Wallet, {
  InvestShop,
  NodeShop,
  Pool,
  EthPool,
  ERC20,
  Router,
  Pair,
  Client,
  utils,
  handleError,
  transfer,
  loading,
  ZERO_ADDRESS,
  InvestMoreShop,
  LpInvestShop
} from '@/contracts/index';
import { contracts } from '@/api/config'
import { Toast } from 'vant';


const CHAIN_ID = process.env.VUE_APP_CHAIN_ID

/**
 * DAPP 合约管理
 */
class Dapp {

  enabled = false

  _contracts = {}

  _contractsInstance = {}

  constructor() {
    // 初始化钱包
    Wallet.setChainId(CHAIN_ID)

    // 合并工具类
    for (let key in utils) {
      Dapp.prototype[key] = utils[key]
    }

    // 等待地址初始化队列
    this._waitingEnabled = []
    // 等待服务端获取合约地址队列
    this._awaitingContracts = []
    // 订阅地址变化事件
    this._onAddressChanged = []

    this._handleListenr()
    this._initContractsAdreess()
  }

  get address() {
    return Client.address
  }

  /**
   * 处理
   */
  _handleListenr() {
    Client.onEnabled(() => {
      this._waitingEnabled.forEach(reslove => reslove());
      this.clearWaiting()
    })

    Client.onAccountChanged((address) => {
      store.commit('updateUser', { address })
      store.dispatch('getUserInfo')
      console.log("12434444",address)
      this._onAddressChanged.forEach(callback => callback(address))
    })
  }


  // 连接钱包
  async onConnect() {
    const err = await Wallet.onConnect()
    if (err) {
      console.log(err)
      Toast.fail(err)
    }
  }

  async _initContractsAdreess() {
    const { err, res } = await contracts()
    if (err === null) {
      this._contracts = res
      this._awaitingContracts.forEach(reslove => reslove());
      this._awaitingContracts = []
    }
  }

  useAddress(callback) {
    if (this.address !== ZERO_ADDRESS) {
      callback(this.address)
    }
    this._onAddressChanged.push(callback)
  }

  clearAddressChanged() {
    if (this._onAddressChanged.length) {
      this._onAddressChanged = []
    }
  }

  /**
   * 等待钱包获取地址成功，等待hook调用
   * @returns 
   */
  async waiting() {
    if (this.address !== ZERO_ADDRESS) {
      return
    }
    // 正常情况，等待钱包地址连接，也同时等待服务端合约地址获取完毕
    await this.awaitingContracts()
    return new Promise((reslove, reject) => {
      this._waitingEnabled.push(() => reslove())
    })
  }

  /**
   * 清除等待钱包初始化钩子
   */
  clearWaiting() {
    if (this._waitingEnabled.length) {
      this._waitingEnabled = []
    }
  }

  /**
   * 等待服务端获取合约地址完毕
   * @returns 
   */
  async awaitingContracts() {
    if (Object.keys(this._contracts).length > 0) {
      return
    }
    return new Promise((reslove, reject) => {
      this._awaitingContracts.push(() => reslove())
    })
  }

  // 调用签名
  async signMessage(message) {
    try {
      loading.show()
      let sig
      if (typeof message !== 'string') {
        // Ref EIP-712, sign data that has a structure
        sig = await Client.signTypedData(message)
        // sig = await this.ethereum.request({ method: 'personal_sign', params: [this.currentAddress, message] })
      } else {
        // Binance Chain Wallet doesn't support signTypedData yet
        sig = await Client.signMessage(message)
      }
      loading.hide()
      return [null, sig]
    } catch (err) {
      loading.hide()
      console.log(err)
      return [handleError(err), null]
    }
  }

  // 转账
  async transfer(to, amount) {
    return await transfer(to, amount)
  }

  /**
   * 获取合约实例
   * @param {*} key 
   * @param {*} contract
   * @returns 
   */
  async _getContractInstance(key, contract) {
    await this.awaitingContracts()
    // TODO: 如果一进入dapp就要链接钱包，将此注释打开更好
    // await this.waiting()
    if (!this._contractsInstance[key]) {
      if (!this._contracts[key]) {
        throw Error(`实例化合约失败，缺少${key}合约地址`)
      }
      this._contractsInstance[key] = new contract(this._contracts[key])
    }
    return this._contractsInstance[key]
  }

  async usdt() {
    return await this._getContractInstance('usdt', ERC20)
  }

  async token() {
    return await this._getContractInstance('token', ERC20)
  }

  async arb() {
    return await this._getContractInstance('arb', ERC20)
  }

  async act() {
    return await this._getContractInstance('act', ERC20)
  }

  async nodeShop() {
    return await this._getContractInstance('nodeShop', NodeShop)
  }


  async investShop() {
    return await this._getContractInstance('investShop', InvestShop)
  }


  async investMoreShop() {
    return await this._getContractInstance('investMoreShop', InvestMoreShop)
  }

  async lpInvestShop() {
    return await this._getContractInstance('lpInvestShop', LpInvestShop)
  }
  
  
  async pool() {
    return await this._getContractInstance('pool', Pool)
  }

  async ethPool() {
    return await this._getContractInstance('ethPool', EthPool)
  }

  async pair() {
    return await this._getContractInstance('pancakePair', Pair)
  }

  async lp() {
    return await this._getContractInstance('lele_usdt_pair', ERC20)
  }

  async router() {
    const token = await this.token()
    const usdt = await this.usdt()
    return await this._getContractInstance('router', Router, token.address, usdt.address)
  }

  async getBalance(address){
    return await Client.getBalance(address)
  }

}

export default new Dapp()
